<!--
 * @Descripttion: 体验主页面
 * @Author: 张明尧
 * @Date: 2021-02-24 10:39:16
 * @LastEditTime: 2021-03-23 09:45:20
-->
<template>
  <div class="experience-div">
    <div class="experience-title">
      企业大脑系统体验
    </div>
    <div class="experience-list" style="min-width: 1000px">
      <div class="experience-list-div">
        <div class="experience-list-div-every" v-for="(listData, _listData) in list" :key="_listData">
          <div class="experience-list-main">
            <div class="experience-user-left">
              <div class="experience-user-photo">
                <img :src="listData.img" alt="">
              </div>
              <div class="experience-user-name">
                {{ listData.name }}
              </div>
              <div class="experience-user-admin">
                <div>
                  账号:{{ listData.number }}
                </div>
                <div>
                  密码:{{ listData.password }}
                </div>
              </div>
              <div @click="openUrl(listData.href)" class="experience-user-href">
                立即体验
              </div>
            </div>
            <div class="experience-user-right">
              <div class="experience-user-right-title">
                体验引导
              </div>
              <div class="experience-user-right-list-main">
                <div :style="listData.lin_height && `line-height: ${listData.lin_height}; margin-top: ${listData.marginTop}`" class="experience-user-right-list" v-for="(text, _text) in listData.main" :key="_text">
                  <span class="experience-user-right-list-title">{{text.title}}：</span>
                  <span class="experience-user-right-list-content">{{text.content}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="experience-phone">
      移动APP案例展示
    </div>
    <div class="experience-phone-erW">
      <img src="../../assets/downImg/experienceApp.png" alt="">
    </div>
  </div>
</template>

<script>
import Datas from './js/common';
export default {
  data() {
    return {
      list: Datas.list,
    }
  },
  methods: {
    openUrl(url) {
      if(!url) return;
      window.open(url);
    }
  }
}
</script>

<style lang="scss" scoped>
  .experience-div {
    width: 1400px;
    padding-top: 50px;
    margin: 0 auto;
    .experience-phone {
      height: 25px;
      font-size: 25px;
      line-height: 25px;
      width: 300px;
      margin: 0 auto;
      font-weight: 600;
      text-align: center;
      position: relative;
      &::before {
        content: '——';
        position: absolute;
        top: 50%;
        width: 50px;
        left: -55px;
        margin-top: -15px;
      }
      &::after {
        content: '——';
        position: absolute;
        top: 50%;
        width: 50px;
        right: -55px;
        margin-top: -15px;
      }
    }
    .experience-phone-erW {
      width: 300px;
      margin: 50px auto;
      img {
        width: 300px;
        height: auto;
      }
    }
    .experience-title {
      color: #3f3f3f;
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 80px;
    }
    .experience-list {
      margin-bottom: 50px;
      .experience-list-div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .experience-list-div-every {
          width: 49%;
        }
      }
      .experience-list-main {
        width: 95%;
        // height: 400px;
        padding: 30px;
        background: #FFFFFF;
        box-shadow: 1px 0px 20px 0px rgba(180, 179, 179, 0.5);
        border-radius: 20px;
        margin: 20px auto 40px auto;
        display: flex;
        flex-wrap: nowrap;
        color: #3f3f3f;
        .experience-user-left {
          width: 200px;
          text-align: center;
          padding-right: 10px;
          border-right: 2px dashed #EDEDED;
          .experience-user-photo {
            img {
              width: 90px;
              height: auto;
            }
          }
          .experience-user-name {
            margin-top: 25px;
            font-size: 22px;
            font-weight: 600;
          }
          .experience-user-admin {
            width: 100%;
            margin-top: 16px;
            font-size: 14px;
            line-height: 1.7;
          }
          .experience-user-href {
            width: 150px;
            padding: 3px;
            margin: 20px auto 0 auto;
            background: #0358FD;
            border-radius: 10px;
            line-height: 38px;
            color: #ffffff;
            font-size: 16px;
            cursor: pointer;
          }
        }
        .experience-user-right {
          width: 450px;
          padding-left: 30px;
          .experience-user-right-list {
            margin-top: 8px;
            .experience-user-right-list-title {
              color: #FF4738;
            }
          }
          .experience-user-right-list-main {
            // display: flex;
            // align-items: center;
            // flex-wrap: wrap;
            // height: 330px;
            line-height: 3;
          }
          .experience-user-right-title {
            font-size: 16px;
            color: #3f3f3f;
            font-weight: 600;
          }
        }
      }
    }
  }
</style>