/*
 * @Descripttion: 组件公用的变量导出
 * @Author: 张明尧
 * @Date: 2021-02-22 15:46:28
 * @LastEditTime: 2021-03-11 11:53:07
 * 
 */
const tj_img = {
  tj1: require('@/assets/ktdn/tj/1.png'),
  tj2: require('@/assets/ktdn/tj/2.png'),
  tj3: require('@/assets/ktdn/tj/3.png'),
  tj4: require('@/assets/ktdn/tj/4.png'),
  tj5: require('@/assets/ktdn/tj/5.png'),
  tj6: require('@/assets/ktdn/tj/6.png'),
};
const defaultUrl = 'http://example.jedanao.com/index.html';
const ITUrl = 'http://www.jepaas.com/demonstration';
const Datas = {
  // 体验中的人员信息
  list: [
    {
      name: '总经理',
      img: tj_img.tj1,
      number: '16800000010',
      password: '123456',
      href: defaultUrl, // 立即体验要跳转的地址
      main: [
        {
          title: '销售展板',
          content: '关注公司整体营销统计分析'
        },
        {
          title: '销售商机',
          content: '关注商机进度、成交几率'
        },
        {
          title: '订单合同',
          content: '关注合同进展，回款情况'
        },
        {
          title: '项目执行',
          content: '关注项目执行进度'
        },
        {
          title: '项目工时报告',
          content: '关注项目工时情况'
        },
      ],
    },
    {
      name: '销售经理',
      img: tj_img.tj2,
      number: '16800000030',
      password: '123456',
      href: defaultUrl, // 立即体验要跳转的地址
      main: [
        {
          title: '销售展板',
          content: '关注公司整体营销统计分析'
        },
        {
          title: '销售商机',
          content: '填报销售商机，跟进销售商机'
        },
        {
          title: '订单合同',
          content: '填报订单合同，回款计划'
        },
        {
          title: '回款记录',
          content: '登记回款记录'
        },
        {
          title: '项目立项',
          content: '项目立项信息，转给项目经理'
        },
      ],
    },
    {
      name: '人事经理',
      img: tj_img.tj3,
      number: '16800000040',
      password: '123456',
      href: defaultUrl, // 立即体验要跳转的地址
      main: [
        {
          title: '人事方面',
          content: '维护全体员工档案等 '
        },
        {
          title: '招聘方面',
          content: '招聘新人、维护简历等'
        },
        {
          title: '考勤方面',
          content: '维护排班策略、规划员工排班等'
        },
        {
          title: '假期方面',
          content: '节假日维护、各类假期发放等 '
        },
        {
          title: '薪酬方面',
          content: '维护工资表、计算员工工资等'
        },
      ],
    },
    {
      name: '研发经理',
      // lin_height: 2.5,
      // marginTop:'0px', 
      img: tj_img.tj4,
      number: '16800000050',
      password: '123456',
      href: defaultUrl, // 立即体验要跳转的地址
      main: [
        {
          title: '项目执行方面',
          content: '关注项目执行进度'
        },
        {
          title: '员工工时产能',
          content: '查看产能工时汇总以及明细'
        },
        {
          title: '员工日常管理',
          content: '每日审核员工诚实守信OKR、关注每日完成成果及次日计划完成日目标'
        },
        {
          title: '每日考勤',
          content: '关注员工日常考勤情况'
        },
      ],
    },
    {
      name: 'IT人员',
      img: tj_img.tj5,
      number: 'admin',
      password: '123',
      href: ITUrl, // 立即体验要跳转的地址
      main: [
        {
          title: '资源表',
          content: '调整数据表结构，增加数据库字段 '
        },
        {
          title: '子系统',
          content: '根据公司业务灵活调整功能列表显示内容，调整表单信息项位置'
        },
        {
          title: '工作流',
          content: '调整工作流流程节点、流转逻辑 '
        },
        {
          title: '数据权限',
          content: '调整使用用户的数据权限'
        },
      ],
    },
    {
      name: '普通员工',
      img: tj_img.tj6,
      number: '16800000051',
      password: '123456',
      href: defaultUrl, // 立即体验要跳转的地址
      main: [
        {
          title: '员工工作台',
          textOmitShow: 2,
          content: '领取本人的项目任务单、项目缺陷单、销售任务，日常工作时进行启动任务 '
        },
        {
          title: '项目执行',
          textOmitShow: 1,
          content: '关注本人参与项目的进展'
        },
        {
          title: '我的工时及产能',
          textOmitShow: 1,
          content: '查看我的每日工时和产能 '
        },
        {
          title: '我的考勤',
          textOmitShow: 1,
          content: '关注自己的每日打卡记录考勤情况'
        },
      ],
    },
  ],
};

export default Datas;